import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/styles"
import theme from "../theme"
import { GlobalStyles } from "@mui/material"

interface IMaterialUIWrapper {
  children: React.ReactNode
}
export default function MaterialUIWrapper(props: IMaterialUIWrapper) {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <GlobalStyles
        styles={`body { font-family: "Exo 2",sans-serif; color: white; }`}
      />
      {props.children}
    </ThemeProvider>
  )
}

MaterialUIWrapper.propTypes = {
  children: PropTypes.node,
}
