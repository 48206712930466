import red from "@mui/material/colors/red"
import { createTheme } from "@mui/material/styles"
// A custom theme for this app
const theme = createTheme({
  palette: {
    //type: 'dark',

    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#222222",
    },
  },
  typography: {
    fontFamily: ["Exo 2", "sans-serif"].join(","),

    //  "'Exo 2',sans-serif",
    /*
    fontFamily: [
      'Exo 2',
      'sans-serif',
    ]

     */
  },
})

export default theme
